import type { UserRole } from '../types/userTypes';

export type EventStatus =
  | 'autovalidated'
  | 'deleted'
  | 'draft'
  | 'edited'
  | 'new'
  | 'notpublished'
  | 'validated';

export type EventType = 'general' | 'mice' | 'association' | 'press';

export type Language = typeof languages[number];
export type LanguageBasic = typeof languagesBasic[number];

export const SORT_BY_UPDATE_KEY = 'updatedAt';
export const SORT_BY_STARTDATE_KEY = 'startDate'; // ! CHANGE TO REAL VALUE
export const SORT_BY_VALIDATION_KEY = 'validatedAt';
export const SORT_BY_LAST_NAME_KEY = 'lastName'; // ! CHANGE TO REAL VALUE
export const SORT_BY_VENUE_KEY = 'venue'; // ! CHANGE TO REAL VALUE

export const propertyGroups = {
  MICHELIN: {
    id: 1,
    name: 'Michelin',
  },
  GAULT_MILLAU: {
    id: 2,
    name: 'Gault Millau',
  },
  BEST_FOR: {
    id: 3,
    name: 'Best for',
  },
  REGION: {
    id: 4,
    name: 'Region',
  },
  ACCOMODATION: {
    id: 5,
    name: 'Accommodation',
  },
  NEIGHBOURHOUD: {
    id: 6,
    name: 'Neighbourhood',
  },
  PAYMENT: {
    id: 7,
    name: 'Payment',
  },
  KITCHEN: {
    id: 8,
    name: 'Kitchen',
  },
  ECOLABEL: {
    id: 11,
    name: 'Ecolabel',
  },
  FOR_GROUPS: {
    id: 12,
    name: 'Pour Groupes',
  },
  MEETING_CATEGORY: {
    id: 13,
    name: 'Meeting venue category',
  },
  VISIT_CATEGORY: {
    id: 14,
    name: 'Visit brussels category',
  },
  SUPPLIER_CATEGORY: {
    id: 15,
    name: 'Prestataire category',
  },
  BRUSSELS_CARD: {
    id: 16,
    name: 'Brussels Card',
  },
  VISIT_PROFILE: {
    id: 17,
    name: 'Visit brussels profile',
  },
  BUDGET: {
    id: 18,
    name: 'Budget',
  },
  VISIT_CONTENT: {
    id: 19,
    name: 'Content group',
  },
};

export const languages = ['fr', 'nl', 'en', 'de', 'it', 'sp', 'pt', 'cn'] as const;

export const languagesBasic = ['fr', 'nl', 'en'] as const;

export const userRoles: UserRole = {
  ADMIN: [
    'canSeeExtraVenueSections',
    'canSeeAllInstitutions',
    'canSeeAllEvents',
    'canSeeSearchBar',
    'canSeeCreateBtn',
    'canSeeVenueActionBtns',
    'canSeeEventActionBtns',
    'canSeeEventPublishBtn',
    'canSeeEventAutoPublishBtn',
    'canSeeEventGuideDescription',
    'canSeeEventBookingLink',
    'canSeeEventSchemaConvertBtn',
    'canSeeAllEventCategories',
    'canSeeAllLanguages',
    'canSeeVenueDistrict',
    'canSeeAdminSection',
    'canSeeMiceSubSection',
    'canSendFullInstitutionData',
    'canUseAdminEndpoint',
    'canEditAllPropertySections',
    'canSeeMasterInNavbar',
    'canSeeMeetingsAndSchools',
    'canSeeUsersInNavbar',
    'canLockEvents',
    'canLinkAllVenues',
    'canEditLockedEventCategory',
    'canEditAdminsEvents',
    'canEditUnlockedEvents',
    'canSeeTechnicalInfo',
    'canAccessAdminRoutes',
    'canSeeVenueBookingLink',
    'canSeeSortOrderField',
    'canEditAdressBookVenue',
    'canEditVenueWithProperties',
    'canLockVenueImport',
    'canEditEventQuickTags',
    'canFilterMICEVenuesInPlaceFiled',
    'canSeeAdditionalPlaceFilters',
    'canSeeDescriptionPrint',
    'canSeeVenueProperties',
    'canSeeIsInAddressBookVenuesList',
    'canSeeEventProgram',
    'canAvoidUppercaseCount',
    'canSeeLegacyId',
    'canTriggerImportedEventsMapping',
    'canRemoveLmtLinkForEvents',
  ],
  ADT: [],
  CRM_QUEUE_MAINTAINER: [],
  EDITOR: [],
  LIMIT_BRUSSELS: [],
  LIMIT_WALLONIA: [],
  MATCHING_PLACE: [],
  MICE: [],
  PRESS: [],
  QUICK_TAGS: [],
  RESTO: [],
  SELECT_PROMOTER: [],
  SPOTS: [],
  SUPER_ADMIN: [
    'canSeeExtraVenueSections',
    'canSeeAllInstitutions',
    'canSeeAllEvents',
    'canSeeSearchBar',
    'canSeeCreateBtn',
    'canSeeVenueActionBtns',
    'canSeeEventActionBtns',
    'canSeeEventPublishBtn',
    'canSeeEventAutoPublishBtn',
    'canSeeEventGuideDescription',
    'canSeeEventBookingLink',
    'canSeeEventSchemaConvertBtn',
    'canSeeAllEventCategories',
    'canSeeAllLanguages',
    'canSeeVenueDistrict',
    'canSeeAdminSection',
    'canSeeMiceSubSection',
    'canSendFullInstitutionData',
    'canUseAdminEndpoint',
    'canEditAllPropertySections',
    'canSeeMasterInNavbar',
    'canSeeMeetingsAndSchools',
    'canSeeUsersInNavbar',
    'canEditUserRole',
    'canLockEvents',
    'canUnlockEvents',
    'canLinkAllVenues',
    'canEditLockedEventCategory',
    'canEditAdminsEvents',
    'canEditUnlockedEvents',
    'canSeeTechnicalInfo',
    'canAccessAdminRoutes',
    'canSeeVenueBookingLink',
    'canSeeSortOrderField',
    'canEditAdressBookVenue',
    'canEditVenueWithProperties',
    'canSeeQuickTagsTab',
    'canEditEventQuickTags',
    'canLockVenueImport',
    'canUnlockVenueImport',
    'canFilterMICEVenuesInPlaceFiled',
    'canSeeAdditionalPlaceFilters',
    'canSeeDescriptionPrint',
    'canSeeVenueProperties',
    'canSeeIsInAddressBookVenuesList',
    'canSeeEventProgram',
    'canAvoidUppercaseCount',
    'canSeeLegacyId',
    'canTriggerImportedEventsMapping',
    'canRemoveLmtLinkForEvents',
  ],
  TAGS: [],
  VALIDATE: [],
  VALIDATE_USERS: [],
};

export const EVENTS_PERSISTED_FILTERS_KEY = 'VB-events-filters';
export const VENUES_PERSISTED_FILTERS_KEY = 'VB-venues-filters';
export const VENUES_PERSISTED_SESSION_KEY = 'VB-venues-session';
export const EVENTS_PERSISTED_SESSION_KEY = 'VB-events-session';
export const USER_PERSISTED_AWAITING_APPROVAL = 'VB-user-awaiting-approval';

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const IMG_BASE_URL = process.env.REACT_APP_IMG_URL;

export const PAGE_SIZE_VENUES = 15;
export const PAGE_SIZE_EVENTS = 15;
export const PAGE_SIZE_IMPORTS = 15;
export const PAGE_SIZE_USERS = 15;

export const EVENT_PUBLICATION_STATES = ['public', 'option', 'embargo'];

export const DAYS = <const>[
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const DAY_POSITION = ['first', 'second', 'third', 'fourth', 'last'];

export const STATUS_DETAILS: {
  [key in string]: { [subKey in string]: string };
} = {
  new: {
    colour: 'warning',
    btnIcon: 'bi-cloud-upload',
    tooltip: 'publish',
  },
  validated: {
    colour: 'success',
    btnIcon: 'bi-x-octagon',
    tooltip: 'unpublish',
  },
  autovalidated: {
    colour: 'success',
    btnIcon: 'bi-x-octagon',
    tooltip: 'unpublish',
  },
  edited: {
    colour: 'info',
    btnIcon: 'bi-x-octagon',
    tooltip: 'unpublish',
  },
  notpublished: {
    colour: 'warning',
    btnIcon: 'bi-cloud-upload',
    tooltip: 'publish',
  },
  deleted: {
    colour: 'secondary',
    btnIcon: 'bi-arrow-counterclockwise',
    tooltip: 'unarchive', // ! Or "restore"?
  },
  draft: {
    colour: 'warning',
    btnIcon: 'bi-arrow-repeat',
    tooltip: 'autoPublish',
  },
};

export const USER_STATUSES = {
  new: 'new',
  validated: 'validated',
  refused: 'refused',
};

export const ONLINE_EVENT_ID = 35414;
export const ACCOMODATION_PROPERTY_ID = 458;
export const START_TAB_QUERY_PARAM = 'startTab';

export const MAX_DATE_TIME = 8640000000000000;
export const MIN_DATE_TIME = -8640000000000000;

export const EVENT_MAIN_CATEGORIES = {
  Exhibition: 23,
  Festival: 118,
  Concert: 1,
  Cinema: 58,
  Clubbing: 57,
};
